<template>
	<div class="policy center">
		<bread-crumb></bread-crumb>
		<el-tabs @tab-click="clickTab" class="zcfg-tabs" v-model="ActiveTab">
			<el-tab-pane :name="item.name" v-for="(item, index) of policyTabLists" :key="index">
		        <span slot="label">
		            {{item.tabName}}
		        </span>
				<el-row :gutter="10">
<!--					<el-col :span="6" v-if="ActiveTab === '5' || ActiveTab === '7'">-->
<!--						<div class="block">-->
<!--							<span class="demonstration">选择地区：</span>-->
<!--							<el-cascader-->
<!--									ref="catalogueExport"-->
<!--									v-model="region"-->
<!--									:options="cascaderData"-->
<!--									:props="{expandTrigger: 'hover', value: 'id',label: 'name',children: 'cities', checkStrictly: true}"-->
<!--									clearable-->
<!--									separator=" - "-->
<!--									@change="handleExportCatalogChange"-->
<!--									@active-item-change="handleItemChange">-->
<!--							</el-cascader>-->
<!--						</div>-->
<!--					</el-col>-->
					<el-col :span="6">
						<div class="block">
							<span class="demonstration">选择年份：</span>
							<el-date-picker
									v-model="date"
									type="year"
									@change="handleChangeDate"
									value-format="yyyy"
                                    :picker-options="dateRange"
									placeholder="选择年">
							</el-date-picker>
						</div>
					</el-col>
					<el-col :span="6" v-if="ActiveTab === '6'">
						<div class="block">
							<span class="demonstration">选择类型：</span>
							<el-select v-model="flfglx" @change="handleChangeType" clearable placeholder="请选择类型">
								<el-option
										v-for="item in lxOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value">
								</el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
				<!-- 使用该组件时，必须传递数据 -->
				<policy-list class="policy-list-box" :listData="policyData"></policy-list>
				<not-found v-if="policyData.length === 0"></not-found>
			</el-tab-pane>
		</el-tabs>
				
		<div class="pagination">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="start"
					:page-sizes="[10, 20, 30, 40]"
					:page-size="size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import policyList from '@/components/policyList'
	import breadCrumb from '@/components/common/breadCrumb'
	import {getGraphInfoByClass} from "../../api/homeService";
	import {getEnumList} from "@/api/homeService";
	import notFound from "../../components/notFound";
	export default {
		components: {
			policyList,
			breadCrumb,
			notFound,
		},
		data() {
			return {
				start:1,
				size:10,
				total:0,
				policyData: [],
				isActive:true,
				ActiveTab:'6',
				date:'',
				flfglx:'',
				region:[],
				cascaderData:[],
				policyTabLists:[
					{
						name:'6',
						tabName:'法律法规',
					},
					{
						name:'7',
						tabName:'重要论述',
					},
					{
						name:'5',
						tabName:'理论研究',
					}
				],
				lxOptions:[
					// {
					// 	value: '1',
					// 	label: '宪法'
					// },
          {
            value: '2',
            label: '法律'
          },
          {
            value: '3',
            label: '中共中央、国务院文件'
          },
          {
						value: '4',
						label: '行政法规'
					},
          {
						value: '5',
						label: '部门规章及规范性文件'
					},
          {
            value: '7',
            label: '地方性法规'
          },
          {
						value: '6',
						label: '司法解释'
					},

				],
                dateRange:{
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6
                    }
                }
			}
		},
		mounted(){
			this.getNodes();
			this.getGraphInfoList();
		},
		methods:{
		    clickTab(tab, event) {
		    	this.date = '';
          this.flfglx = '';
          this.region = [];
          this.start = 1;
		      this.getGraphInfoList();
		    },
		  	getGraphInfoList(){
          const num = this.region.length
          const data = {
            lx: this.ActiveTab,
            start:this.start,
            size:this.size,
            qxdm:num>0 ? this.region[num-1] : '',
            fbnf:this.date,
            flfglx:this.flfglx,
          };
          getGraphInfoByClass(data).then(res=>{
            this.policyData=res.data;
            this.total = res.total;
          })
			},
			handleSizeChange(val){
				this.size = val;
				this.getGraphInfoList();
			},
			handleCurrentChange(val){
				this.start = val;
				this.getGraphInfoList();
			},
			handleClick(tab, event) {
			    console.log(tab, event);
			},
			handleExportCatalogChange() {
				this.$refs.catalogueExport.dropDownVisible = false;
				this.getGraphInfoList();
			},
			handleChangeType(){
				this.getGraphInfoList();
			},
			handleChangeDate(){
				this.getGraphInfoList();
			},
			/**
			 * 获取下一级操作
			 * */
			handleItemChange(val) {
				this.getNodes(val)
			},
			/**
			 * 获取地区枚举
			 * @param fid
			 */
			async getDqMj(fid) {
				const mj = {
					'fid': fid,
					'zudm': 'xzqEnum'
				}
				const result = await getEnumList(mj)
				if (result.code === '0') {
					return result.data
				}
			},
			/**
			 * 获取下一级区县
			 * @param val
			 */
			getNodes(val) {
				let idArea
				let sizeArea
				if (!val) {
					idArea = null
					sizeArea = 0
				} else if (val.length === 1) {
					idArea = val[0]
					sizeArea = val.length // 3:一级 4:二级 6:三级
				} else if (val.length === 2) {
					idArea = val[1]
					sizeArea = val.length // 3:一级 4:二级 6:三级
				}
				if (sizeArea === 0) { // 初始化 加载一级 省
					this.getDqMj('xzq0').then(response => {
						if (response) {
							this.cascaderData = response.map((value, i) => {
								return {
									id: value.mcdm,
									name: value.mc,
									cities: []
								}
							})
						}
					})
				} else if (sizeArea === 1) { // 点击一级 加载二级 市
					this.getDqMj('xzq' + idArea).then(response => {
						if (response) {
							this.cascaderData.map((value, i) => {
								if (value.id === val[0]) {
									if (!value.cities.length) {
										value.cities = response.map((value, i) => {
											return {
												id: value.mcdm,
												name: value.mc,
												cities: []
											}
										})
									}
								}
							})
						}
					})
				} else if (sizeArea === 2) { // 点击二级 加载三级 区
					this.getDqMj('xzq' + idArea).then(response => {
						if (response) {
							this.cascaderData.map((value, i) => {
								if (value.id === val[0]) {
									value.cities.map((value, i) => {
										if (value.id === val[1]) {
											if (!value.cities.length) {
												value.cities = response.map((value, i) => {
													return {
														id: value.mcdm,
														name: value.mc
													}
												})
											}
										}
									})
								}
							})
						}
					})
				}
			},
		}
	}
</script>
<style lang="scss">
	.policy {
		.active {
			color: rgba(0, 120, 255, 1);
		}
		.policy-list-box{
			.policy-list-item:first-child{
				.el-row{
					border-top: 1px solid rgba(217, 217, 217, 1);
				}
			}
		}
		.zcfg-tabs {
		    border: none;
		    box-shadow: none;
		    .block{
				padding:15px 0px;
			}
		    .el-tabs__header {
		        margin: 20px 0 0px;
		        border-bottom:1px solid #ECF1F3;
		        background-color: transparent;
		
		        .el-tabs__item {
		            border: none;
		            font-size: 16px;
		            padding: 0px 0px !important;
		            color: #000000;
		            line-height: 40px;
					width: 192px;
					height: 40px;
					border-radius: 6px 6px 0px 0px;
					text-align: center;
		        }
		
		        .el-tabs__item.is-active {
		            background-color: #ECF1F3;
		        }
		
		        .el-tabs__item:not(.is-active):hover {
		            color: #808080;
		        }
		
		        .el-tabs__active-bar {
		            display: none;
		        }
		    }
		
		    .el-tabs__content {
		        padding: 0 !important;
		    }
		
		    .el-tabs__nav-wrap::after {
		        display: none;
		    }
		}
	}
	.pagination{
		text-align:center;
		margin:20px 0;
		.el-pagination{
			font-weight:500;
		}
	}
</style>
